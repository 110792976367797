import cx from 'classix';
import {
  type ChangeEvent,
  type ReactNode,
} from 'react';
import { FaAngleDown } from 'react-icons/fa6';

import styles from './select-native.module.scss';

interface SelectNativeProps {
  children: ReactNode;
  label: string;
  name: string;
  disabled?: boolean;
  // eslint-disable-next-line unicorn/no-keyword-prefix
  className?: string;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
}

interface OptionProps {
  value: string;
  label: string;
  selected?: boolean;
  disabled?: boolean;
}

function Option(props: OptionProps) {
  const {
    disabled,
    label,
    selected,
    value,
  } = props;

  return (
    <option
      value={value}
      selected={selected}
      disabled={disabled}
    >
      {label}
    </option>
  );
}

function SelectNative(props: SelectNativeProps) {
  const {
    children,
    // eslint-disable-next-line unicorn/no-keyword-prefix
    className,
    disabled,
    label,
    name,
    onChange,
  } = props;

  return (
    <div className={styles.selectNative}>
      <select
        className={cx(styles.selectNative_select, className)}
        aria-label={label}
        name={name}
        disabled={disabled}
        onChange={onChange}
      >
        {children}
      </select>

      <FaAngleDown className={styles.selectNative_arrow} />
    </div>
  );
}

SelectNative.Option = Option;

export default SelectNative;
